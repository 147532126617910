import React, { useEffect } from "react";
import Input from "react-verification-input";
import styles from "./styles.module.scss";

export function VerificationInput({ onChange, value }) {
	useEffect(() => {
		const ac = new AbortController();

		const getOtp = () => {
			/* istanbul ignore next */
			if ("OTPCredential" in window) {
				setTimeout(() => {
					ac.abort();
				}, 3 * 60 * 1000);

				const credentialRequest = {
					otp: { transport: ["sms"] },
					signal: ac.signal
				};

				navigator.credentials
					.get(credentialRequest)
					.then((OTPCredential) => {
						const credential = OTPCredential;
						if (credential?.code) {
							onChange(credential.code);
						}
					})
					.catch((error) => {
						if (error?.name !== "Abort Error") {
							ac.abort();
						}
					});
			}
		};
		getOtp();
		return () => ac.abort();
	}, []);

	return (
		<Input
			classNames={{
				container: styles.containerCharacters,
				character: styles.character,
				characterInactive: styles.characterInactive,
				characterSelected: styles.characterSelected,
				characterFilled: styles.characterFilled
			}}
			autoFocus
			length={4}
			placeholder=""
			validChars="0-9"
			onChange={onChange}
			value={value}
			inputProps={{
				autoComplete: "one-time-code",
				type: "text",
				name: "code",
				inputMode: "numeric",
				pattern: "[0-9]*"
			}}
		/>
	);
}
