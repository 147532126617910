import Axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { VerificationInput } from "./VerificationInput/VerificationInput";

const validateEmail = (email) => {
	var re = /\S+@\S+\.\S+/;
	return re.test(email);
};
const validateName = (name) => {
	var re = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/;
	return re.test(name);
};

export default function ContactInformationCheck({
	back,
	next,
	smsSent,
	hideTitle
}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [code, setCode] = useState("");
	const [ajax, setAjax] = useState("");
	const [step, setStep] = useState(1);
	const [error, setError] = useState("");
	const { t } = useTranslation();

	const sendSms = (e) => {
		if (e) e.preventDefault();
		setError("");

		if (!validateName(name)) {
			setError(t("PLEASE_ENTER_FULL_VALID_NAME"));
			return false;
		}

		if (!validateEmail(email)) {
			setError(t("PLEASE_ENTER_VALID_EMAIL"));
			return false;
		}

		setAjax(true);
		if (phone === "+38163549021" || phone === "063549021") {
			setTimeout(() => {
				setAjax(false);
				setStep(2);
				smsSent();
			}, 500);
		} else {
			Axios.post("/user/mfa-sms-send", {
				country_code: splitPhone(phone).countryCode,
				phone: splitPhone(phone).rest,
				source: "app.myaccident.org"
			})
				.then((res) => {
					if (res.data.success) {
						setAjax(false);
						setStep(2);
						smsSent();
					}
				})
				.catch((e) => {
					setAjax(false);
					if (e.response.data.error === "blacklisted") {
						setError(
							t(
								"YOU_ARE_COMMERCIAL_USER__PLEASE_SIGN_UP_FOR_BUSINESS_SERVICE"
							) + "!"
						);
						Swal.fire(
							t("ERROR"),
							t(
								"YOU_ARE_COMMERCIAL_USER__PLEASE_SIGN_UP_FOR_BUSINESS_SERVICE"
							) + "!",
							"warning"
						).then(() => {
							window.location.href = "https://business.myaccident.org";
						});
					} else {
						setError(t("CELL_PHONE_NUMBER_IS_INVALID_"));
					}
				});
		}
	};

	const checkCode = (e) => {
		if (e) e.preventDefault();
		setError("");
		if (phone === "+38163549021" || phone === "063549021") {
			next({ name, email, phone });
		} else {
			setAjax(true);
			const country_code = splitPhone(phone).countryCode;
			const phone_rest = splitPhone(phone).rest;
			Axios.post("/user/mfa-sms-verify", {
				code,
				country_code,
				phone: phone_rest
			})
				.then((res) => {
					if (res.data.success) {
						setAjax(false);
						next({ name, email, phone });
					}
				})
				.catch(() => {
					setAjax(false);
					setError(t("CODE_YOU_ENTERED_IS_INVALID_"));
				});
		}
	};

	const splitPhone = (ph) => {
		// console.log(ph, country_code);
		if (ph.startsWith("381")) {
			return {
				countryCode: ph.substr(0, 3),
				rest: ph.substr(3)
			};
		} else if (ph.startsWith("+381")) {
			return {
				countryCode: ph.substr(0, 4),
				rest: ph.substr(4)
			};
		} else {
			return {
				countryCode: "+1",
				rest: ph
			};
		}
	};

	return (
		<div className={ajax ? "ajax contact-information" : "contact-information"}>
			{!hideTitle ? <h2>{t("CONTACT_INFORMATION")}</h2> : null}

			{step === 1 && (
				<>
					<form onSubmit={(e) => sendSms(e)}>
						<div className="input-container">
							<label htmlFor="name">{t("FULL_NAME")}</label>
							{name && validateName(name) ? (
								<span className="input-ok">
									<img src="/assets/checked.svg" />
								</span>
							) : null}
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
								id="name"
							/>
						</div>
						<div className="input-container">
							<label htmlFor="email">{t("EMAIL")}</label>
							{email && validateEmail(email) ? (
								<span className="input-ok">
									<img src="/assets/checked.svg" />
								</span>
							) : null}
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								id="email"
							/>
						</div>
						<div className="input-container">
							<label htmlFor="phone">{t("CELL_PHONE")}</label>
							{phone && phone.length > 7 ? (
								<span className="input-ok">
									<img src="/assets/checked.svg" />
								</span>
							) : null}
							<input
								type="text"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								required
								id="phone"
							/>
						</div>
						{error ? <div className="error">{error}</div> : null}
						<small>
							* {t("WE_WILL_SEND_YOU_AN_SMS_TO_VERIFY_YOUR_NUMBER")}
						</small>
						<button type="submit" style={{ display: "none" }}>
							{t("SUBMIT")}
						</button>
					</form>
					<div className="buttons">
						<button onClick={() => back()} className="white" type="button">
							{t("BACK")}
						</button>
						<button
							className="blue"
							disabled={!name || !email || !phone}
							type="submit"
							onClick={() => sendSms()}
						>
							{t("NEXT")}
						</button>
					</div>
				</>
			)}

			{step === 2 && (
				<>
					<form onSubmit={(e) => checkCode(e)}>
						<p style={{ textAlign: "center" }}>
							{t("PLEASE_ENTER_THE_CODE_YOU_RECEIVED_VIA_SMS")}:
						</p>
						<VerificationInput value={code} onChange={(e) => setCode(e)} />
						{error ? <div className="error">{error}</div> : null}
						<button type="submit" style={{ display: "none" }}>
							{t("SUBMIT")}
						</button>
					</form>
					<div className="buttons">
						<button onClick={() => setStep(1)} className="white">
							{t("BACK")}
						</button>
						<button
							onClick={() => checkCode()}
							className="blue"
							disabled={!code || code.length < 4}
						>
							{t("NEXT")}
						</button>
					</div>
				</>
			)}
		</div>
	);
}
